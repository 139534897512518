import React, { useState, useEffect } from 'react';
import { ReactComponent as Visa } from 'assets/images/cards/visa.svg';
import { ReactComponent as Mastercard } from 'assets/images/cards/masterCard.svg';
import { ReactComponent as BelCard } from 'assets/images/cards/belcard.svg';
import { ReactComponent as Mir } from 'assets/images/cards/mir.svg';
import { useAxiosSubmitingEffect } from 'utils/hooks/axiosHook';
import { getUserPaymentOptionById } from 'utils/services/request/card';
import { convertCardNumber } from 'utils/number';
import { CARD_TYPES } from 'utils/constants/paymentMethods';
import { CardInfo, CardNumber, ImageType, Label, Wrapper } from './styled-ui';

const getCardIconByType = brand => {
  const value = typeof brand === 'string' ? brand.toUpperCase() : null;
  switch (value) {
    case CARD_TYPES.BELCARD:
      return BelCard;
    case CARD_TYPES.MIR:
      return Mir;
    case CARD_TYPES.MASTERCARD:
      return Mastercard;
    case CARD_TYPES.VISA:
      return Visa;
    default:
      return null;
  }
};

const CardView = ({ paymentTokenId, provider, label }) => {
  const [card, setCard] = useState(null);
  const stateSubmitingForm = useAxiosSubmitingEffect(
    getUserPaymentOptionById({ id: paymentTokenId, provider })
  );

  useEffect(() => {
    if (!stateSubmitingForm.fetching && stateSubmitingForm.loaded) {
      setCard(stateSubmitingForm.data);
    }
  }, [stateSubmitingForm]);

  if (!card) {
    return null;
  }
  const CardIconComponent = getCardIconByType(card.brand);
  return (
    <>
      <Wrapper>
        <Label>{label}</Label>
        <CardInfo>
          <ImageType>{CardIconComponent && <CardIconComponent />}</ImageType>
          <CardNumber>{convertCardNumber(card.number)}</CardNumber>
        </CardInfo>
      </Wrapper>
    </>
  );
};

export default CardView;
