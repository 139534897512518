import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty, isNil } from 'ramda';
import { parse } from 'query-string';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PersonalAccount from 'pages/PersonalAccount';
import PendingStatus from 'components/PendingStatus';
import ProcessingStatus from 'components/ProcessingStatus';
import Exchanger from 'components/Exchanger';
import { HomeWrapper, ExchangerBlock } from 'components/Exchanger/styled-ui';
import Presentation from 'components/Presetation/index';
import ErrorModal from 'components/ErrorModal';
import { pushAnalyticsEvent } from 'components/App/analytics';
import ContentModalSocialMedia from 'components/ContentModalSocialMedia';
import { SimpleModal } from 'ui-kit/Modal/Modal';
import { CONFIRMED, PROCESSING, REQUEST } from 'utils/constant';
import {
  useAxiosStateWithRefetch,
  useAxiosSubmitingEffect,
} from 'utils/hooks/axiosHook';
import {
  auditRequestData,
  removeUtmLabels,
  setCurrenciesByParams,
  setUtmLabels,
} from 'utils/utmLabels';
import { getUserRole, isUserAnonymous } from 'utils/services/selectors/user';
import { getOrdersDetails } from 'utils/services/request/operations';
import auditRecords from 'utils/services/request/audit';
import UserContext from 'utils/contexts/User';
import ClientContext from 'utils/contexts/Client';
import CurrenciesContext from 'utils/contexts/Currencies';
import OrderDetails from 'utils/contexts/OrderDetails';
import USER_ROLES from 'utils/constants/userRoles';
import {
  CURRENCY_KEY_TYPES,
  UTM_LABEL_TYPES,
} from 'utils/constants/utmLabelTypes';
import CryptoVibeModal from 'components/CryptoVibeModal';

const Home = ({ setNoActiveOrder }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const queryParams = parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const [data, setExchangeOperationData] = useState(null);
  const [isOrderUpdated, setIsOrderUpdated] = useState(false);
  const [error, setError] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowCryptoVibeModal, setIsShowCryptoVibeModal] = useState(false);
  const user = useContext(UserContext);
  const client = useContext(ClientContext);
  const currencies = useContext(CurrenciesContext);
  const orderDetails = useContext(OrderDetails);
  const isUserMerchant = getUserRole(data, USER_ROLES.MERCHANT);
  const isDefaultUser =
    isUserAnonymous(user) || getUserRole(user, USER_ROLES.USER);
  const condition =
    user.loaded && user.data && isDefaultUser ? getOrdersDetails : {};
  const exchangeOperationState = useAxiosStateWithRefetch(condition);
  const getAuditRecords = useAxiosSubmitingEffect(auditRecords);
  const isDisplayExchanger = data === null;
  const isClientDataError =
    !client?.data?.countryCode && client?.data?.status === 'VERIFIED';
  const isNotActiveOrder = !data;

  useEffect(() => {
    if (history.location.state?.isShowSocialModal) {
      setIsShowModal(true);
    }
    return () => {
      window.history.replaceState({}, '');
    };
  }, [history]);

  useEffect(() => {
    if (exchangeOperationState.loaded) {
      setExchangeOperationData(exchangeOperationState.data);
      if (!isNil(exchangeOperationState.data)) {
        setIsOrderUpdated(true);
      }
    }
    if (exchangeOperationState.fetching) {
      setIsOrderUpdated(false);
    }
    if ((user.loaded && isUserMerchant) || isNil(data)) {
      setNoActiveOrder(true);
    } else {
      setNoActiveOrder(false);
    }
  }, [
    data,
    exchangeOperationState.data,
    exchangeOperationState.loaded,
    exchangeOperationState.fetching,
    setNoActiveOrder,
    isUserMerchant,
    user.loaded,
  ]);

  useEffect(() => {
    const currencyPair = `${data?.exchangeOperation?.currencyPair?.fromCurrency}_${data?.exchangeOperation?.currencyPair?.toCurrency}`;
    if (data?.status === PROCESSING) {
      pushAnalyticsEvent(`exchange_start_${currencyPair}`);
    } else if (data?.status === CONFIRMED) {
      pushAnalyticsEvent(`exchange_success_${currencyPair}`);
    }
  }, [data?.status, data?.exchangeOperation?.currencyPair]);

  useEffect(() => {
    if (currencies?.loaded && !currencies?.data && data === null) {
      setTimeout(() => {
        setError(true);
      }, 500);
    }
  }, [currencies, data]);

  useEffect(() => {
    const currentTime = moment().format();
    const promoCode = JSON.parse(localStorage.getItem('promocode'));
    const isExpired =
      localStorage.getItem('promocode') &&
      !queryParams?.promocode &&
      currentTime > promoCode?.expiration;
    const isUsed = !isEmpty(data?.promoCode) && data?.status === CONFIRMED;

    if (isExpired || isUsed) {
      localStorage.removeItem('promocode');
    }
  }, [queryParams?.promocode, data?.status, data?.promoCode]);

  // Collection functionality utm labels
  const fetchAuditRecords = () =>
    getAuditRecords.setFormAndSubmit({
      values: auditRequestData(),
    });

  useEffect(() => {
    const utmLabels = Object.keys(queryParams).filter(value =>
      UTM_LABEL_TYPES.concat(CURRENCY_KEY_TYPES).includes(value)
    );

    if (utmLabels.length > 0) {
      setUtmLabels(queryParams);
      setCurrenciesByParams(queryParams);
    }
  }, [queryParams]);

  useEffect(() => {
    const utmLabels = UTM_LABEL_TYPES.filter(value => localStorage[value]);
    if (user.data && utmLabels.length > 0) {
      fetchAuditRecords();
    }
  }, [user.data]);

  useEffect(() => {
    const isRequestSuccess =
      getAuditRecords?.loaded &&
      !getAuditRecords?.fetching &&
      getAuditRecords?.error === 'Not found';

    if (isRequestSuccess) removeUtmLabels();
  }, [
    getAuditRecords.loaded,
    getAuditRecords.fetching,
    getAuditRecords?.error,
  ]);

  useEffect(() => {
    orderDetails.refetch();
  }, [isNotActiveOrder]);

  return isDefaultUser ? (
    <HomeWrapper isOrderView={!!data}>
      {data === null && <Presentation />}
      <ExchangerBlock>
        {!isClientDataError && !error ? (
          <>
            {isDisplayExchanger && (
              <Exchanger
                isOrderUpdated={isOrderUpdated}
                refresh={exchangeOperationState.refetch}
              />
            )}
          </>
        ) : (
          <ErrorModal
            title={
              isClientDataError
                ? t('errorModal.titleClientError')
                : t('errorModal.title')
            }
            isReloadButton={error}
          />
        )}
        {data?.status === REQUEST && (
          <PendingStatus data={data} refresh={exchangeOperationState.refetch} />
        )}
        {data?.exchangeOperation && data?.status !== REQUEST && (
          <ProcessingStatus
            data={data}
            isOrderUpdated={isOrderUpdated}
            refetch={exchangeOperationState.refetch}
            fetching={exchangeOperationState.fetching}
            user={user}
          />
        )}
      </ExchangerBlock>
      {isShowModal && (
        <SimpleModal
          onClose={() => setIsShowModal(false)}
          Component={ContentModalSocialMedia}
        />
      )}
      {isShowCryptoVibeModal && (
        <SimpleModal
          centered
          backgroundColorWrapper="rgba(0, 0, 0, 0.3)"
          padding="0px"
          maxWidth="770px"
          width="auto"
          onClose={() => setIsShowCryptoVibeModal(false)}
          Component={CryptoVibeModal}
        />
      )}
    </HomeWrapper>
  ) : (
    <PersonalAccount />
  );
};

export default Home;
