export const FACE_SDK_ACTIONS = {
  ELEMENT_VISIBLE: 'ELEMENT_VISIBLE',
  PRESS_START_BUTTON: 'PRESS_START_BUTTON',
  PRESS_RETRY_BUTTON: 'PRESS_RETRY_BUTTON',
  CLOSE: 'CLOSE',
  PROCESS_FINISHED: 'PROCESS_FINISHED',
  SERVICE_INITIALIZED: 'SERVICE_INITIALIZED',
  RETRY_COUNTER_EXCEEDED: 'RETRY_COUNTER_EXCEEDED',
};

export const DOCUMENT_SDK_ACTIONS = {
  ELEMENT_VISIBLE: 'ELEMENT_VISIBLE',
  PRESS_CAMERA_BUTTON: 'PRESS_CAMERA_BUTTON',
  PRESS_FILE_BUTTON: 'PRESS_FILE_BUTTON',
  PRESS_RETRY_BUTTON: 'PRESS_RETRY_BUTTON',
  PRESS_SKIP_BUTTON: 'PRESS_SKIP_BUTTON',
  PRESS_CAPTURE_BUTTON: 'PRESS_CAPTURE_BUTTON',
  PRESS_CHANGE_CAMERA_BUTTON: 'PRESS_CHANGE_CAMERA_BUTTON',
  PRESS_MIRRORING_BUTTON: 'PRESS_MIRRORING_BUTTON',
  NEW_PAGE_AVAILABLE: 'NEW_PAGE_AVAILABLE',
  NEW_PAGE_STARTED: 'NEW_PAGE_STARTED',
  CLOSE: 'CLOSE',
  CAMERA_PROCESS_CLOSED: 'CAMERA_PROCESS_CLOSED',
  CAMERA_PROCESS_STARTED: 'CAMERA_PROCESS_STARTED',
  VIDEO_STARTED: 'VIDEO_STARTED',
  VIDEO_STOPPED: 'VIDEO_STOPPED',
  FILE_PROCESS_STARTED: 'FILE_PROCESS_STARTED',
  PROCESS_FINISHED: 'PROCESS_FINISHED',
  SERVICE_INITIALIZED: 'SERVICE_INITIALIZED',
};

export const DOCUMENT_TYPES = {
  PASSPORT: 'PASSPORT',
  ID_CARD: 'ID_CARD',
  RESIDENT_CARD: 'RESIDENT_CARD',
  REUPLOAD: 'REUPLOAD',
};

export const DOCUMENT_TYPES_CAMEL = {
  PASSPORT: 'passport',
  ID_CARD: 'idCard',
  RESIDENT_CARD: 'residentCard',
  REUPLOAD: 'reupload',
};

export const REGULA_MODES = {
  changeDocuments: 'change-documents',
  changeRegistration: 'change-registration',
};

export const REGULA_PARSING_ERRORS = {
  PROCESSING: 'Processing error',
  ADULT: 'User must be over 18 years old',
};
