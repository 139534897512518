import {
  cardAddUrlOptions as attachAssistCard,
  attachGazpromCard,
  getRemoveUserAssistCardUrl,
  getRemoveUserGazpromCardUrl,
  getRemoveUserSettlementUrl,
  attachBepaidCard,
  getRemoveUserBepaidCardUrl,
  attachMtsCard,
  getRemoveUserMtsCardUrl,
} from 'utils/services/request/card';
import {
  getCryptoCardDetails,
  attachAlfaCard,
  removeAlfaCard,
} from 'utils/services/request/alfa';

// providers
export const GAZPROM = 'BGP';
export const ASSIST = 'ASSIST';
export const SETTLEMENT_ACCOUNT = 'CA';
export const ERIP = 'ERIP';
export const BEPAID = 'BEPAID';
export const ALFA = 'ALFA';
export const MTS = 'MTS';

// card types by commission
export const SYSTEM_CARD_TYPES = {
  ASSIST: 'ASSIST',
  ASSIST_BELARUSBANK: 'ASSIST_BELARUSBANK',
  SETTLEMENT_ACCOUNT: 'CA',
  ERIP: 'ERIP',
  BEPAID: 'BEPAID',
  ALFA: 'ALFA',
  ALFA_OTHER: 'ALFA_OTHER',
  MTS: 'MTS',
};

// types
export const CARD_PAYMENT_SERVICE = 'CARD_PAYMENT_SERVICE';
export const SETTLEMENT = 'CA';
export const CRYPTO_CARD = 'CRYPTO_CARD';
export const ALFA_PAYMENT_SERVICE = 'ALFA_PAYMENT_SERVICE';
export const ASSIST_PAYMENT_SERVICE = 'ASSIST_PAYMENT_SERVICE';

// banks
export const BELARUSBANK = 'BELARUSBANK';

export const PROVIDER_TYPES = {
  ASSIST,
  GAZPROM,
  SETTLEMENT,
  ERIP,
  BEPAID,
  ALFA,
};

export const ORDER_TYPES = {
  CARD: 'add_card',
  SETTLEMENT: 'settlement_order',
  ERIP: 'erip_order',
};

export const CARD_TYPES = {
  MASTERCARD: 'MASTERCARD',
  VISA: 'VISA',
  ERIP: 'ERIP',
  MIR: 'MIR',
  BELCARD: 'BELCARD',
};

export const PAYMENT_STATUS = {
  ENABLED: 'ENABLED',
  DIRECTION_DISABLED: 'DIRECTION_DISABLED',
  CURRENCY_DISABLED: 'CURRENCY_DISABLED',
};

const returnUrl = `${
  window.location.origin
}/profile?tab=payments${encodeURIComponent('&card_bind=success')}`;

const attachAlfaCardRequest = attachAlfaCard({
  failUrl: `${window.location.origin}/profile?tab=payments&card_bind=failed`,
  returnUrl: `${window.location.origin}/profile?tab=payments&card_bind=success`,
});

export const PAYMENT_METHODS = [
  {
    id: ALFA,
    type: CRYPTO_CARD,
    attachCardRequest: attachAlfaCardRequest,
    getRemoveCardUrl: removeAlfaCard,
    getCardDetailsRequest: getCryptoCardDetails,
  },
  {
    type: ALFA_PAYMENT_SERVICE,
    paymentServices: [
      {
        id: ALFA,
        cardTypeList: [SYSTEM_CARD_TYPES.ALFA, SYSTEM_CARD_TYPES.ALFA_OTHER],
        attachCardRequest: attachAlfaCardRequest,
        getRemoveCardUrl: removeAlfaCard,
      },
    ],
  },
  {
    type: ASSIST_PAYMENT_SERVICE,
    paymentServices: [
      {
        id: ASSIST,
        cardTypeList: [
          SYSTEM_CARD_TYPES.ASSIST_BELARUSBANK,
          SYSTEM_CARD_TYPES.ASSIST,
        ],
        attachCardRequest: attachAssistCard(returnUrl),
        getRemoveCardUrl: getRemoveUserAssistCardUrl,
      },
    ],
  },
  {
    type: CARD_PAYMENT_SERVICE,
    paymentServices: [
      // {
      //   id: BEPAID,
      //   cardTypeList: [SYSTEM_CARD_TYPES.BEPAID],
      //   attachCardRequest: attachBepaidCard(returnUrl),
      //   getRemoveCardUrl: getRemoveUserBepaidCardUrl,
      // },
      {
        id: MTS,
        cardTypeList: [SYSTEM_CARD_TYPES.MTS],
        attachCardRequest: attachMtsCard,
        getRemoveCardUrl: getRemoveUserMtsCardUrl,
      },
    ],
  },
  // {
  //   id: GAZPROM,
  //   type: CARD_PAYMENT_SERVICE,
  //   attachCardRequest: attachGazpromCard,
  //   getRemoveCardUrl: getRemoveUserGazpromCardUrl,
  // },
  // {
  //   id: ERIP,
  //   type: ERIP,
  // },
  {
    id: SETTLEMENT_ACCOUNT,
    type: SETTLEMENT,
    getRemoveCardUrl: getRemoveUserSettlementUrl,
  },
];

export const REDIRECT_AFTER_PAY_URL = {
  [PROVIDER_TYPES.ASSIST]: `&url_return=${window.location.origin}/order-payment`,
  [PROVIDER_TYPES.GAZPROM]: `&back_url_s=${window.location.origin}/order-payment&back_url_f=${window.location.origin}/order-payment`,
  [PROVIDER_TYPES.ALFA]: `&back_url_s=${window.location.origin}/order-payment`,
};
