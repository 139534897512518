import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useTranslation, Trans } from 'react-i18next';
import { defineComponents } from '@regulaforensics/vp-frontend-document-components';
import ReactFlagsSelect from 'react-flags-select';
import { isEmpty } from 'ramda';
import {
  DOCUMENT_SDK_ACTIONS,
  DOCUMENT_TYPES,
  DOCUMENT_TYPES_CAMEL,
  REGULA_MODES,
  REGULA_PARSING_ERRORS,
} from 'utils/constants/regula';
import { COUNTRY_CODES, DIGITAL_COUNTRY_CODES } from 'utils/countryCodes';
import observerWC from 'utils/webComponents';
import { Button } from 'ui-kit/Button';
import {
  verificationDocumentsComplete,
  verificationDocumentsUrl,
} from 'utils/services/request/verifications';
import DocumentDetails from 'components/Regula/DocumentDetails';
import stylesRegulaComponent from 'components/Regula/utils';
import { ReactComponent as UploadIcon } from 'assets/images/upload.svg';
import passportCorrect from 'assets/images/verification/i_passport_correct.e3e8efdd.png';
import passportIncorrect from 'assets/images/verification/i_passport_incorrect_1.293168d3.png';
import passportIncorrect2 from 'assets/images/verification/i_passport_incorrect_2.02c0839f.png';
import idCardCorrect from 'assets/images/verification/i_idcard_correct.e6412a46.png';
import idCardIncorrect from 'assets/images/verification/i_idcard_incorrect_1.42c3b612.png';
import idCardIncorrect2 from 'assets/images/verification/i_idcard_incorrect_2.c13c1bd7.png';
import validationCameraSnapshot from './validation';
import {
  Message,
  ButtonsContainer,
  CameraSnapshotWrapper,
  DeleteIcon,
  FlagsWrapper,
  InstructionImages,
  RadioInput,
  SnapshotWrapper,
  Thumb,
  ThumbImage,
  ThumbsButton,
  ThumbsContainer,
  ThumbWrapper,
  TitleContainer,
  TypesDocWrapper,
} from './styled-ui';

const CameraSnapshot = ({
  handleOnSubmit,
  handleLoader,
  livenessResult,
  operatingMode,
}) => {
  const { t } = useTranslation();
  const isChangeRegistration =
    operatingMode === REGULA_MODES.changeRegistration;
  const [isComponentOpen, setIsComponentOpen] = useState(false);
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(true);
  const [isDocParseSuccess, setIsDocParseSuccess] = useState(false);
  const [docCountryCode, setDocCountryCode] = useState(COUNTRY_CODES.BY);
  const [docType, setDocType] = useState(
    isChangeRegistration ? DOCUMENT_TYPES.REUPLOAD : DOCUMENT_TYPES.PASSPORT
  );
  const [errors, setErrors] = useState({});
  const [notifications, setNotifications] = useState({});
  const [images, setImages] = useState({});
  const [currentImage, setCurrentImage] = useState('');
  const [parsedFields, setParsedFields] = useState(null);
  const elementRef = useRef();
  const containerRef = useRef();

  const instructionImagesPassport = [
    passportCorrect,
    passportIncorrect,
    passportIncorrect2,
  ];
  const instructionImagesCard = [
    idCardCorrect,
    idCardIncorrect,
    idCardIncorrect2,
  ];

  const docTypes = [
    DOCUMENT_TYPES.PASSPORT,
    DOCUMENT_TYPES.RESIDENT_CARD,
    DOCUMENT_TYPES.ID_CARD,
  ];

  const buttons = {
    passport: {
      1: t('verification.regula.documents.passport.firstPage'),
      2: t('verification.regula.documents.passport.secondPage'),
      3: t('verification.regula.documents.passport.thirdPage'),
    },
    residentCard: {
      1: t('verification.regula.documents.residentCard.firstPage'),
      2: t('verification.regula.documents.residentCard.secondPage'),
      3: t('verification.regula.documents.residentCard.thirdPage'),
    },
    idCard: {
      1: t('verification.regula.documents.idCard.firstPage'),
      2: t('verification.regula.documents.idCard.secondPage'),
      3: t('verification.regula.documents.idCard.thirdPage'),
    },
  };

  const listener = useCallback(
    data => {
      const { action } = data.detail;
      if (action === DOCUMENT_SDK_ACTIONS.PROCESS_FINISHED) {
        const status = data.detail.data?.status;
        const isFinishStatus = status === 1;
        if (!isFinishStatus || !data.detail.data?.response) return;
        const isExpired = JSON.parse(localStorage.getItem('imageExpired'));
        if (isExpired) {
          setErrors({
            message: t('verification.regula.documents.imageExpired'),
          });
        } else {
          const image = data.detail.data.response[0].raw;
          setImages({ ...images, [currentImage]: image });
          setErrors({});
          setNotifications({});
        }
        localStorage.setItem('imageExpired', JSON.stringify(false));
        setIsComponentOpen(false);
      }
      if (action === DOCUMENT_SDK_ACTIONS.CLOSE) {
        setIsComponentOpen(false);
      }
    },
    [images, currentImage, setErrors, setImages, setIsComponentOpen, t]
  );

  useEffect(() => {
    const isRequiredImages = isChangeRegistration
      ? !!images.image1
      : !!images.image1 && !!images.image2 && !!images.image3;
    setIsSendButtonDisabled(!isRequiredImages);
  }, [images, isChangeRegistration]);

  useEffect(() => {
    const containerCurrent = containerRef.current;
    defineComponents();
    if (!containerCurrent) return;
    containerCurrent.addEventListener('camera-snapshot', listener);
    // eslint-disable-next-line consistent-return
    return () => {
      containerCurrent.removeEventListener('camera-snapshot', listener);
    };
  }, [currentImage, images, listener]);

  useEffect(() => {
    const elementRefCurrent = elementRef.current;
    if (!elementRefCurrent) return;
    if (elementRefCurrent.shadowRoot) {
      observerWC.observe(elementRefCurrent.shadowRoot, {
        childList: true,
        subtree: true,
      });
    }
    elementRefCurrent.settings = {
      locale: 'ru',
      startScreen: true,
      multipleFileInput: false,
      changeCameraButton: true,
      statusIcon: true,
      closeDisabled: true,
      customization: stylesRegulaComponent,
    };
  }, [isComponentOpen]);

  const getBlob = async image => {
    if (!image) return null;
    const url = `data:image/jpeg;base64,${image}`;
    const res = await fetch(url);
    const blob = await res.blob();
    return blob;
  };

  const getErrorMessage = error => {
    switch (error) {
      case REGULA_PARSING_ERRORS.PROCESSING:
        return t('verification.documentsProcess.processingError');
      case REGULA_PARSING_ERRORS.ADULT:
        return t('verification.documentsProcess.adultError');
      default:
        return t('verification.documentsProcess.unknownError');
    }
  };

  useEffect(() => {
    const isSuccess = isEmpty(errors) && notifications.success;
    setIsDocParseSuccess(isSuccess);
    setIsSendButtonDisabled(!isEmpty(errors));
  }, [errors, notifications]);

  const sendHandler = async () => {
    setErrors({});
    setNotifications({});
    const params = {
      docCountryCode:
        docCountryCode === COUNTRY_CODES.BY
          ? DIGITAL_COUNTRY_CODES.BY
          : docCountryCode,
      docType,
    };
    const formData = new FormData();
    const blobs = [
      await getBlob(images.image1, 1),
      await getBlob(images.image2, 2),
      await getBlob(images.image3, 3),
    ];
    blobs.forEach((blob, index) => {
      if (blob) {
        const fileName = `image${index + 1}`;
        formData.append(fileName, blob, `${fileName}.jpeg`);
      }
    });
    if (handleLoader) {
      handleLoader(true);
    }
    axios
      .post(verificationDocumentsUrl, formData, {
        params,
      })
      .then(response => {
        if (response.status === 200) {
          validationCameraSnapshot(
            response?.data,
            t,
            setErrors,
            setNotifications
          );
          setParsedFields(response?.data);
        }
      })
      .catch(e => {
        setErrors({
          message: getErrorMessage(e.response?.data?.message),
        });
        setIsSendButtonDisabled(true);
      })
      .finally(() => {
        if (handleLoader) {
          handleLoader(false);
        }
      });
  };

  const deleteImageHandler = name =>
    setImages(prevState => ({ ...prevState, [name]: null }));

  const refreshHandler = () => {
    setImages([]);
    setIsDocParseSuccess(false);
    setIsSendButtonDisabled(true);
    setParsedFields(null);
    setErrors({});
    setNotifications({});
  };

  const doneHandler = () => {
    axios
      .post(verificationDocumentsComplete, {
        livenessPath: livenessResult || '',
      })
      .then(() => {
        if (handleOnSubmit) {
          handleOnSubmit();
        }
      })
      .catch(() => {
        setErrors({
          message: t('verification.documentsProcess.unknownError'),
        });
      });
  };

  const printInstructionImages = array =>
    array.map((item, index) => (
      <img src={item} alt={`image_${index}`} key={index} />
    ));

  const addImageHandler = name => {
    setCurrentImage(name);
    setIsComponentOpen(true);
  };

  const changeDoctypeHandler = e => {
    setDocType(e.target.value);
    setImages({});
  };

  const printRadioInput = type => (
    <RadioInput key={type}>
      <input
        type="radio"
        id={type}
        name="docType"
        value={type}
        checked={docType === type}
        onChange={changeDoctypeHandler}
        disabled={isChangeRegistration}
      />
      <label htmlFor={type}>
        {t(`verification.documentTypes.${DOCUMENT_TYPES_CAMEL[type]}`)}
      </label>
    </RadioInput>
  );

  const thumbsButton = index => {
    const typeDocCamelCase = DOCUMENT_TYPES_CAMEL[docType];
    const textButton = buttons[typeDocCamelCase];
    return (
      <ThumbsButton
        onClick={() => addImageHandler(`image${index}`)}
        key={index}
      >
        <UploadIcon />
        {isChangeRegistration
          ? t('verification.regula.modes.changeRegTitle')
          : textButton[index]}
      </ThumbsButton>
    );
  };

  const getMessagesByType = (values, type) =>
    Object.entries(values).map(([key, value]) => (
      <Message type={type} key={key}>
        {value}
      </Message>
    ));

  const printThumb = indexes =>
    indexes.map(index =>
      images[`image${index}`] ? (
        <ThumbWrapper key={index}>
          <Thumb>
            {!isDocParseSuccess && (
              <DeleteIcon onClick={() => deleteImageHandler(`image${index}`)} />
            )}
            <ThumbImage
              src={`data:image/jpeg;base64,${images[`image${index}`]}`}
              alt={`preview_image_${index}`}
            />
          </Thumb>
        </ThumbWrapper>
      ) : (
        thumbsButton(index)
      )
    );

  const buttonProps = {
    width: '200px',
    maxwidth: '200px',
    padding: '10px',
    type: 'button',
  };

  return (
    <SnapshotWrapper>
      {!isChangeRegistration && (
        <span>{t('verification.regula.documents.title')}</span>
      )}
      {!isChangeRegistration && (
        <FlagsWrapper>
          <span>{t('verification.regula.documents.label')}</span>
          <ReactFlagsSelect
            selected={docCountryCode}
            onSelect={setDocCountryCode}
          />
        </FlagsWrapper>
      )}
      <div>{t('verification.chooseDocumentType')}</div>
      <TypesDocWrapper>{docTypes.map(printRadioInput)}</TypesDocWrapper>
      <InstructionImages>
        {printInstructionImages(
          docType === DOCUMENT_TYPES.PASSPORT
            ? instructionImagesPassport
            : instructionImagesCard
        )}
      </InstructionImages>
      <TitleContainer>
        <p>
          {isChangeRegistration
            ? t('verification.regula.modes.changeRegTitle')
            : t(
                `verification.regula.documents.${DOCUMENT_TYPES_CAMEL[docType]}.title`
              )}
        </p>
        <p>
          <Trans i18nKey="verification.regula.documents.condition" t={t} />
        </p>
        <p>
          {isChangeRegistration
            ? t(`verification.regula.documents.registration`)
            : t(
                `verification.regula.documents.${DOCUMENT_TYPES_CAMEL[docType]}.description`
              )}
        </p>
        <p>
          {isChangeRegistration && (
            <Trans
              i18nKey="verification.regula.documents.changeRegistration"
              t={t}
            />
          )}
        </p>
      </TitleContainer>
      <ThumbsContainer>
        {isChangeRegistration ? printThumb([1]) : printThumb([1, 2, 3])}
      </ThumbsContainer>
      {errors && getMessagesByType(errors, 'error')}
      {isEmpty(errors) && notifications && getMessagesByType(notifications)}
      <ButtonsContainer>
        {(!isEmpty(errors) || isDocParseSuccess) && (
          <Button {...buttonProps} onClick={refreshHandler}>
            {t('verification.refreshButton')}
          </Button>
        )}
        {isDocParseSuccess ? (
          <Button {...buttonProps} onClick={doneHandler}>
            {t('verification.doneButton')}
          </Button>
        ) : (
          <Button
            {...buttonProps}
            onClick={sendHandler}
            disabled={isSendButtonDisabled}
          >
            {t('verification.sendButton')}
          </Button>
        )}
      </ButtonsContainer>
      <DocumentDetails fields={parsedFields} />
      <div ref={containerRef}>
        {isComponentOpen && (
          <fullscreen-container>
            <CameraSnapshotWrapper>
              <camera-snapshot ref={elementRef} />
            </CameraSnapshotWrapper>
          </fullscreen-container>
        )}
      </div>
    </SnapshotWrapper>
  );
};

export default CameraSnapshot;
