import { localeNumberFromCustomNumber } from 'utils/number';
import { isCrypto, getCurrencyValue } from 'utils/crypto';
import { FIAT_CURRENCIES } from 'utils/constant';
import { CARD_TYPES, PROVIDER_TYPES } from 'utils/constants/paymentMethods';

const validationFieldsValue = (clientStatus, t) => values => {
  let errors = {};
  const limit = JSON.parse(localStorage.getItem('limit'));

  const isNotBynOperation =
    values.currencyPair?.fromCurrency !== FIAT_CURRENCIES.BYN &&
    values.currencyPair?.toCurrency !== FIAT_CURRENCIES.BYN;
  if (
    values.brand === CARD_TYPES.BELCARD &&
    values.providerType === PROVIDER_TYPES.ALFA &&
    isNotBynOperation
  ) {
    errors = {
      ...errors,
      calculation: {
        inputAsset: t('calculationView.errors.validationErrorAlfaOnlyByn'),
      },
    };
  }

  if (!values.calculation.inputAsset) {
    errors = {
      ...errors,
      calculation: {
        inputAsset: t('calculationView.errors.validationErrorInputAsserEmpty'),
      },
    };
  }

  if (values.calculation.inputAsset) {
    const value = localeNumberFromCustomNumber(
      values.calculation.inputAsset,
      values.currencyPair.fromCurrency
    )
      .replace(/,/g, '.')
      .replace(/\s/g, '');
    if (limit.min > limit.max || limit.max === 0 || limit.max === null) {
      if (clientStatus === 'VERIFIED') {
        errors = {
          ...errors,
          calculation: {
            inputAsset: t(
              'calculationView.errors.validationErrorLimitExceeded'
            ),
          },
        };
      }
    } else if (value < limit.min) {
      errors = {
        ...errors,
        calculation: {
          inputAsset: t(
            'calculationView.errors.validationErrorOutDownOfLimit',
            {
              value: limit.min,
              currency: getCurrencyValue(values.currencyPair.fromCurrency),
            }
          ),
        },
      };
    } else if (value > limit.max) {
      errors = {
        ...errors,
        calculation: {
          inputAsset: t('calculationView.errors.validationErrorOutUpOfLimit'),
        },
      };
    }
  }

  if (isCrypto(values.currencyPair.toCurrency)) {
    if (!values.address) {
      errors = {
        ...errors,
        address: t('calculationView.errors.validationErrorWalletEmpty'),
      };
    }
  }

  if (
    !values.bankAccount &&
    values.orderType === 'WT' &&
    isCrypto(values.currencyPair.fromCurrency)
  ) {
    errors = {
      ...errors,
      bankAccount: t('calculationView.errors.validationErrorBankAccountEmpty'),
    };
  }

  return errors;
};

export default validationFieldsValue;
