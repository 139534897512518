import styled from 'styled-components';
import device from 'device';

export const StyledTable = styled.table`
  border-collapse: collapse;
  margin: 0 auto;
  width: 100%;

  @media ${device.mobile920} {
    width: 800px;
  }
`;

export const THead = styled.thead`
  border-bottom: 1px solid rgb(226, 226, 226);
`;

export const TH = styled.th`
  height: 35px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  white-space: nowrap;
  padding: 0 10px;
  margin: 0;
  text-align: left;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
    text-align: right;
  }

  @media ${device.desktop1280} {
    font-size: 13px;
  }
`;

export const TD = styled.td`
  height: 35px;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  padding: 0 10px;
  margin: 0;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
    text-align: end;
  }

  @media ${device.desktop1280} {
    font-size: 13px;
  }
`;

export const TBody = styled.tbody``;

export const TDEmpty = styled.td.attrs({
  colSpan: 5,
})`
  text-align: center;
`;

export const NotFound = styled.div`
  width: max-content;
  margin: 10px auto;
  color: ${props => props.theme.fullBlackText};
`;
