import blueTheme from 'components/App/themes/blue';

const stylesRegulaComponent = {
  fontFamily: 'Inter',
  onboardingScreenStartButtonBackground: blueTheme.blueBg,
  onboardingScreenStartButtonBackgroundHover: blueTheme.blueBg,
  retryScreenRetryButtonBackground: blueTheme.blueBg,
  retryScreenRetryButtonBackgroundHover: blueTheme.blueBg,
  cameraScreenStrokeNormal: 'none',
};
export default stylesRegulaComponent;
