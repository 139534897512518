import styled from 'styled-components';
import device from 'device';

export const SnapshotWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  gap: 15px;
  > span {
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 10px;
  }
`;

export const FlagsWrapper = styled.div`
  position: relative;
  > span {
    color: ${({ theme }) => theme.blueBg};
    font-size: 0.7rem;
    line-height: 19px;
    position: absolute;
    top: -10px;
    left: 11px;
    background-color: ${({ theme }) => theme.fullWhite};
    padding: 0 5px;
    z-index: 1;
  }
`;

export const TypesDocWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 15px;
  @media ${device.mobileL} {
    width: 100%;
    flex-direction: column;
  }
`;

export const RadioInput = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 7px;
  input {
    margin: 0px;
    cursor: pointer;
  }
  label {
    white-space: nowrap;
  }
  @media ${device.mobileL} {
    width: 100%;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  border: 1px solid ${({ theme }) => theme.borderCard};
  font-size: 14px;
  border-radius: 8px;
  color: ${({ theme }) => theme.darkgray};
`;

export const ThumbsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;

  &:hover {
    border-color: ${({ theme }) => theme.dimgray};
  }
  svg {
    width: 50px;
    height: 50px;
    path {
      fill: #000;
    }
  }
`;

export const ThumbWrapper = styled.div`
  flex: 1;
  border: 1px solid ${({ theme }) => theme.grayBg};
  display: flex;
  justify-content: center;
`;

export const Thumb = styled.div`
  position: relative;
  min-width: 140px;
  max-width: inherit;
  min-height: 140px;
  max-height: 160px;
  padding: 4px;
  box-sizing: border-box;
  img {
    max-height: inherit;
    max-width: inherit;
  }
`;

export const ThumbImage = styled.img`
  display: block;
  width: auto;
  height: 100%;
`;

export const ThumbsButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px dashed ${({ theme }) => theme.dimgray};
  background-color: ${({ disabled, theme }) =>
    disabled ? 'none' : theme.grayBg};
  margin-bottom: 8px;
  flex: 1;
  min-width: 140px;
  height: 160px;
  padding: 4px;
  box-sizing: border-box;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  border-radius: 8px;
  transition: background-color 0.3s;
  font-weight: 600;
  svg {
    path {
      transition: fill 0.3s;
      fill: ${({ theme }) => theme.dimgray};
    }
  }
  &:hover {
    border: 1px dashed
      ${({ disabled, theme }) => (disabled ? theme.dimgray : theme.darkgray)};
    background-color: ${({ disabled, theme }) =>
      disabled ? theme.grayBg : theme.lightBlueWhite};
    svg {
      path {
        fill: ${({ disabled, theme }) =>
          disabled ? theme.dimgray : theme.darkgray};
      }
    }
  }
`;

export const InstructionImages = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  img {
    max-width: 160px;
    min-width: 100px;
  }
  @media ${device.mobileL} {
    flex-direction: column;
    align-items: center;
    img {
      max-width: 50%;
    }
  }
`;

export const DeleteIcon = styled.div`
  background: url(${props => props.theme.cancelImage}) no-repeat;
  width: 24px;
  height: 24px;
  position: absolute;
  top: -7px;
  right: 4px;
  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

export const Message = styled.div`
  background-color: ${({ type, theme }) =>
    type === 'error' ? theme.borderAlertSuccess : theme.borderAlertError};
  border: ${({ type }) =>
    type === 'error' ? '1px solid #ffccc7' : '1px solid #b7eb8f'};
  display: flex;
  flex-direction: column;
  padding: 15px;
  font-size: 14px;
  border-radius: 8px;
`;

export const CameraSnapshotWrapper = styled.div`
  height: 100%;
  camera-snapshot {
    backdrop-filter: blur(10px);
    background: none;
    display: flex;
    align-items: center;
  }
`;
