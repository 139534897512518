const validationCameraSnapshot = (values, t, setErrors, setNotifications) => {
  if (!values?.containsSignaturePage) {
    setErrors(errors => ({
      ...errors,
      containsSignaturePage: t(
        'verification.documentsProcess.containsSignaturePage'
      ),
    }));
  }

  if (!values?.portraitIsColorness) {
    setErrors(errors => ({
      ...errors,
      portraitIsColorness: t(
        'verification.documentsProcess.portraitIsColorness'
      ),
    }));
  }

  if (values?.oneOrMoreImageOutOfFocus) {
    setErrors(errors => ({
      ...errors,
      oneOrMoreImageOutOfFocus: t(
        'verification.documentsProcess.oneOrMoreImageOutOfFocus'
      ),
    }));
  }

  const requiredFields = [
    'firstNameRu',
    'lastNameRu',
    'patronymicRu',
    'firstName',
    'lastName',
    'placeOfBirth',
    'birthDate',
    'identityDocType',
    'identityDocIssueDate',
    'identityDocExpireDate',
    'identityDocNumber',
    'identityDocIssuer',
    'gender',
    'nationality',
  ];

  if (values && requiredFields.every(field => values[field]?.length > 0)) {
    setNotifications(notifications => ({
      ...notifications,
      success: t('verification.documentsProcess.success'),
    }));
  } else {
    setErrors(errors => ({
      ...errors,
      oneOrMoreImageOutOfFocus: t('verification.documentsProcess.errorParse'),
    }));
  }
};

export default validationCameraSnapshot;
