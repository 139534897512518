const responseErrors = {
  INVALID_SCAN_STATUS:
    'Инициализация сервиса, повторите попытку через несколько минут',
  INVALID_ACTUAL_RATE:
    'В данный момент курс по выбранной паре недоступен. Обратитесь в службу поддержки',
  INVALID_SPREAD: 'Расчет средств невозможен. Обратитесь в службу поддержки',
  INVALID_CHANGE_TO_ADDRESS:
    'Расчет средств невозможен. Обратитесь в службу поддержки',
  INVALID_UTXO_SUM: 'Направление обмена временно недоступно',
  INVALID_INPUT_ADDRESS: 'Направление обмена временно недоступно',
  INVALID_FIAT_BALANCE: 'Направление обмена временно недоступно',
  INVALID_CRYPTO_BALANCE: 'Направление обмена временно недоступно',
  INVALID_CORPORATE_CARD: 'Направление обмена временно недоступно',
  INVALID_CLIENT_CARD: 'Направление обмена временно недоступно',
  FROZEN_CRYPTO_ADDRESS:
    'Ваш внутренний адрес для пополнения криптовалюты заморожен. Обратитесь в службу поддержки',
  INVALID_EXTERNAL_RATE:
    'Не удалось рассчитать индивидуальный курс. Обратитесь в службу поддержки',
  FORCE_BLOCKED_CLIENT:
    'Выявлен высокий риск использования адреса для осуществления противоправной деятельности. Обратитесь в службу поддержки',
  BAD_REQUEST: 'Произошла непредвиденная ошибка. Обратитесь в службу поддержки',
  INVALID_WT_STATUS: 'Операции по расчетным счетам заблокированы',
  INVALID_INTERNAL_CLIENT_BALANCE_AMOUNT: 'Недостаточно средств на кошельке',
  INVALID_FIAT_PROVIDER_CURRENCY:
    'Операция для выбранной карты возможна только в RUB',
  INVALID_PROVIDER_TYPE: 'Вы не добавили средство платежа',
  NO_ADDRESS_AVAILABLE: 'Торговля недоступна. Попробуйте через несколько минут',
};

export default responseErrors;
